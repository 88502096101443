import React, { useState } from 'react';
import { Navbar, Nav, Container, Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Energy = () => {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <Navbar
        bg='transparent'
        expand='lg'
        className='custom-navbar'
        expanded={expanded}
        onToggle={() => setExpanded(!expanded)}
      >
        <Navbar.Brand
          as={Link}
          to='/'
          style={{ color: 'white', fontWeight: 'bold', marginTop: '-6px' }}
        >
          &Theta;
        </Navbar.Brand>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            <Button
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderColor: 'transparent',
                backgroundColor: '#177b9c',
                textDecoration: 'underline',
                width: 'max-content',
                marginLeft: '-7px',
              }}
              as={Link}
              to='/Advisory'
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#177b9c')}
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = 'transparent')
              }
            >
              Theta Advisory
            </Button>
            <Button
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textDecoration: 'underline',
                width: 'max-content',
                marginLeft: '-5px',
              }}
              as={Link}
              to='/Solution'
            >
              Theta Solution
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
        <Navbar
          bg='transparent'
          expand='lg'
          className='custom-navbar'
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
        >
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Nav.Link as={Link} to='/Advisory/ProjectExpertise'>
                <div className='dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = '#177b9c')
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = 'transparent')
                      }
                    >
                      ESG Data Project Expertise
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionFinancial'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ESG Data Project Solutions for Financial Institutions
                      </Dropdown.Item>

                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionNonFinancial'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ESG Data Project Solutions for Non-Financial
                        Institutions
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
              <Nav.Link as={Link} to=''>
                <div className='dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: '#177b9c',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                    >
                      Boosting Your Energy Qualifications
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/ISO'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ISO Certification Training
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Audit'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Audit and Consulting Services
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Energy'
                        style={{
                          backgroundColor: '#177b9c',
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                      >
                        Energy Management
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <br />
        <br />
        <br />
        <br />
        <Container>
          <h5>
            Expert Guidance in Energy Consumption Control and Optimization
          </h5>
          <ul>
            <li>
              At Thêta, we understand the critical importance of efficient
              energy management for the success and sustainability of your
              business. Our mission is to empower clients by providing
              comprehensive support in monitoring and optimizing their energy
              consumption, ensuring both operational efficiency and cost
              savings.
            </li>
          </ul>
          <h5>Tailored Energy Optimization Strategies</h5>
          <ul>
            <li>
              Our approach goes beyond surface-level recommendations. We conduct
              a deep technical and financial analysis to ensure that the
              proposed solutions are not only effective but also economically
              viable. This rigorous evaluation allows us to identify the most
              cost-effective strategies for enhancing your energy performance.
            </li>
          </ul>
          <h5>Strategic Implementation for Long-Term Success</h5>
          <ul>
            <li>
              After delivering our analysis and recommendations, we work closely
              with your team to implement the optimized energy strategies. Our
              experts provide ongoing support to ensure that the changes lead to
              measurable improvements, helping your organization achieve
              sustained energy efficiency and reduced operational costs.
            </li>
          </ul>
          <h5>Proven Expertise and Results</h5>
          <ul>
            <li>
              With a proven track record of helping organizations across various
              industries, Thêta is a trusted partner in energy management. Our
              deep industry knowledge, combined with our commitment to
              delivering tailored, actionable solutions, ensures that your
              business can achieve its energy performance goals with confidence.
            </li>
          </ul>
        </Container>
      </div>
    </>
  );
};

export default Energy;
