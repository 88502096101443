import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLinkedin } from 'react-icons/fa';
import ContactForm from './ContactForm';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <Container>
        <Row>
          <Col className='text-center py-3'>
            Theta Fintech AI. All Rights Reserved &copy; {currentYear} |
            <a
              href='https://www.linkedin.com/company/theta-fintech-ai/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FaLinkedin
                size={24}
                color='#ffffff'
                style={{ marginLeft: '10px', marginRight: '10px' }}
              />
            </a>
            | <ContactForm />
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
