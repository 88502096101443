import React, { useState } from 'react';
import '../App.css';
import '../index.css';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container , Button } from 'react-bootstrap';
const Main = () => {
  // State to control navbar collapse
  const [expanded, setExpanded] = useState(true); // Initialize to true to keep it open by default

  return (
    <>
      <Navbar
        bg='transparent'
        expand='lg'
        className='custom-navbar'
        expanded={expanded} // Control expanded state
        onToggle={() => setExpanded(!expanded)} // Toggle the state when clicking on the Navbar.Toggle
      >
        <Navbar.Brand
          as={Link}
          to='/'
          style={{ color: 'white', fontWeight: 'bold', marginTop: '-6px' }}
        >
          &Theta;
        </Navbar.Brand>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            <Button
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textDecoration: 'underline',
                width: 'max-content',
                marginLeft: '-7px',
              }}
              as={Link}
              to='/Advisory'
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#177b9c')}
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = 'transparent')
              }
            >
              Theta Advisory
            </Button>
            <Button
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textDecoration: 'underline',
                width: 'max-content',
                marginLeft: '-5px',
              }}
              as={Link}
              to='/Solution'
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#177b9c')}
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = 'transparent')
              }
            >
              Theta Solution
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '30vh',
        }}
      >
        <div className='logo'>
          <img
            src={'/theta_new.png'}
            alt='Description'
            style={{
              maxWidth: '50%',
              animation: 'rotate360 2s infinite linear',
            }}
          />
        </div>
      </div>
      <div className='why'>
        <Container>
          <img
            src='https://readme-typing-svg.demolab.com?font=Fira+Code&size=21&pause=1000&color=F7F7F7&width=800&height=500&lines=Why+Theta%3F;Theta+can+be+thought+of+as+the+subconscious;Theta+holds+memories+and+sensations.;Theta+governs+attitudes%2C+beliefs%2C+and+behaviors.+;Theta+is+creative%2C+inspirational%2C+and+very+spiritual.;Theta+allows+you+to+act+below+the+level+of+the+conscious+mind.'
            alt='Typing SVG'
          />
        </Container>
      </div>
    </>
  );
};

export default Main;
