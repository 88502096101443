import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    subject: 'ESG Data Project Expertise',
    name: '',
    email: '',
    message: '',
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        'https://theta-server.onrender.com/api/contact',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        toast.success('Message sent successfully!', {
          autoClose: 3000, // Close after 3 seconds
          onClose: handleClose, // Close the modal after toast
        });
      } else {
        toast.error('Failed to send message.', {
          autoClose: 3000, // Close after 3 seconds
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.', {
        autoClose: 3000, // Close after 3 seconds
      });
    }
  };

  return (
    <>
      <Button
        variant='transparent'
        style={{
          color: 'white',
        }}
        onClick={handleShow}
      >
        Contact
      </Button>

      <Modal
        className='custom-modal'
        show={show}
        onHide={handleClose}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} style={{ color: 'white' }}>
            <Form.Group controlId='formSubject'>
              <Form.Label>Subject</Form.Label>
              <Form.Control
                as='select'
                name='subject'
                value={formData.subject}
                onChange={handleChange}
              >
                <option>ESG Data Project Expertise</option>
                <option>Boosting Your Energy Qualifications</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='formName'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                name='name'
                value={formData.name}
                onChange={handleChange}
                placeholder='Enter your name'
                required
              />
            </Form.Group>

            <Form.Group controlId='formEmail'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                placeholder='Enter your email'
                required
              />
            </Form.Group>

            <Form.Group controlId='formMessage'>
              <Form.Label>Message</Form.Label>
              <Form.Control
                as='textarea'
                name='message'
                value={formData.message}
                onChange={handleChange}
                rows={3}
                placeholder='Enter your message'
                required
              />
            </Form.Group>
            <br />
            <Button
              variant='transparent'
              type='submit'
              style={{ color: 'black', backgroundColor: 'white' }}
            >
              Send Message
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='transparent'
            onClick={handleClose}
            style={{ color: 'black', backgroundColor: 'white' }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toast Container */}
      <ToastContainer />
    </>
  );
};

export default ContactForm;
