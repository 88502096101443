import React, { useState } from 'react';
import { Navbar, Nav, Container, Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ISOModal from './ISOModal';

const Boosting = () => {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <Navbar
        bg='transparent'
        expand='lg'
        className='custom-navbar'
        expanded={expanded} // Control expanded state
        onToggle={() => setExpanded(!expanded)} // Toggle the state when clicking on the Navbar.Toggle
      >
        <Navbar.Brand
          as={Link}
          to='/'
          style={{ color: 'white', fontWeight: 'bold', marginTop: '-6px' }}
        >
          &Theta;
        </Navbar.Brand>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            <Button
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderColor: 'transparent',
                backgroundColor: '#177b9c',
                textDecoration: 'underline',
                width: 'max-content',
                marginLeft: '-7px',
              }}
              as={Link}
              to='/Advisory'
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#177b9c')}
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = 'transparent')
              }
            >
              Theta Advisory
            </Button>
            <Button
              style={{
                color: 'white',
                fontWeight: 'bold',
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textDecoration: 'underline',
                width: 'max-content',
                marginLeft: '-5px',
              }}
              as={Link}
              to='/Solution'
            >
              Theta Solution
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
        <Navbar
          bg='transparent'
          expand='lg'
          className='custom-navbar'
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
        >
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Nav.Link as={Link} to='/Advisory/ProjectExpertise'>
                <div className='dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = '#177b9c')
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = 'transparent')
                      }
                    >
                      ESG Data Project Expertise
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionFinancial'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ESG Data Project Solutions for Financial Institutions
                      </Dropdown.Item>

                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/ProjectExpertise/SolutionNonFinancial'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ESG Data Project Solutions for Non-Financial
                        Institutions
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
              <Nav.Link as={Link} to='/Advisory/Boosting'>
                <div className='dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: '#177b9c',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                    >
                      Boosting Your Energy Qualifications
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      ></div>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/ISO'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        ISO Certification Training
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Audit'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Audit and Consulting Services
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Advisory/Boosting/Energy'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Energy Management
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <br />
      <br />
      <br /> <br />
      <Container>
        <h4>Boosting Your Employees’ Energy Qualifications:</h4>
        <hr />
        <h5> Empowering Clients with ISO 50001 Certification Training</h5>
        <ul>
          <li>
            We are dedicated to helping our clients educate their employees on
            various ISO certifications, specifically targeting Energy Management
            and Energy Cost Consumption. By partnering with top leading
            certification agencies, we deliver comprehensive training programs
            across industries in France. Our expertise ensures that our clients
            achieve excellence in energy efficiency and sustainability.
          </li>
          <li>
            Investing in your employees, <ISOModal />{' '}
          </li>
        </ul>
        <h5>Audit and Consulting Services</h5>
        <ul>
          <li>
            We specialize in auditing companies' existing energy management
            systems in accordance with ISO 50001 standards. Our expert team
            provides comprehensive audit reports and actionable recommendations
            to help your organization achieve ISO 50001 certification and ensure
            full compliance with the standard.
          </li>
        </ul>
        <h5>Energy Management</h5>
        <ul>
          <li>
            We assist companies in reducing their energy consumption by
            developing a sustainable energy strategy and a multi-year action
            plan. Our comprehensive approach ensures long-term efficiency
            improvements and cost savings, helping your business achieve its
            energy goals while promoting sustainability.
          </li>
        </ul>
      </Container>
    </>
  );
};

export default Boosting;
