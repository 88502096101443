import React, { useState } from 'react';
import { Navbar, Nav, Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Whytheta = () => {
  const [expanded, setExpanded] = useState(true);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

 const [isDropdownOpen2, setDropdownOpen2] = useState(false);

 const handleMouseEnter2 = () => {
   setDropdownOpen2(true);
 };

 const handleMouseLeave2 = () => {
   setDropdownOpen2(false);
 };

 const handleMouseEnter = () => {
   setDropdownOpen(true);
 };

 const handleMouseLeave = () => {
   setDropdownOpen(false);
 };

  return (
    <>
      <div>
        <Navbar
          bg='transparent'
          expand='lg'
          className='custom-navbar'
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
        >
          <Navbar.Brand
            as={Link}
            to='/'
            style={{ color: 'white', fontWeight: 'bold', marginTop: '-6 px' }}
          >
            &Theta;
          </Navbar.Brand>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Button
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  borderColor: 'transparent',
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                  width: 'max-content',
                  marginLeft: '-15px',
                }}
                as={Link}
                to='/Advisory'
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = '#177b9c')
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = 'transparent')
                }
              >
                Theta Advisory
              </Button>
              <Button
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  borderColor: 'transparent',
                  backgroundColor: '#177b9c',
                  textDecoration: 'underline',
                  width: 'max-content',
                  marginLeft: '-5px',
                }}
                as={Link}
                to='/Solution'
              >
                Theta Solution
              </Button>{' '}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div>
        <Navbar
          bg='transparent'
          expand='lg'
          className='custom-navbar'
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
        >
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Button
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  borderColor: 'transparent',
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                  width: 'max-content',
                  marginLeft: '-12px',
                  marginBottom: '15px',
                }}
                as={Link}
                to='/expertise'
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = '#177b9c')
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = 'transparent')
                }
              >
                Introduction
              </Button>
              <Nav.Link as={Link} to='/Sovereign'>
                <div className='dropdown'>
                  <Dropdown
                    show={isDropdownOpen}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = '#177b9c')
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = 'transparent')
                      }
                    >
                      Sovereign Data
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            as={Link}
                            to=''
                            style={{
                              fontWeight: 'bold',
                              color: 'white',
                              textDecoration: 'none',
                              backgroundColor: 'transparent',
                            }}
                          >
                            ESG
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            style={{
                              fontWeight: 'bold',
                              color: 'white',
                              textDecoration: 'none',
                              backgroundColor: 'transparent',
                              backdropFilter: 'blur(5px)',
                            }}
                          >
                            <Dropdown.Item
                              as={Link}
                              to='/esg/Environmental'
                              style={{
                                fontWeight: 'bold',
                                color: 'white',
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                                backdropFilter: 'blur(5px)',
                              }}
                            >
                              Environmental
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              to='/esg/Social'
                              style={{
                                fontWeight: 'bold',
                                color: 'white',
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                                backdropFilter: 'blur(5px)',
                              }}
                            >
                              Social
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              to='/esg/Governance'
                              style={{
                                fontWeight: 'bold',
                                color: 'white',
                                textDecoration: 'none',
                              }}
                            >
                              Governance
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <Dropdown.Item
                        as={Link}
                        to='/Controversies'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Controversies
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Controversies'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Climate
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Biodiversity'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Biodiversity
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
              <Nav.Link as={Link} to='/Corporate'>
                <div className='dropdown'>
                  <Dropdown
                    show={isDropdownOpen2}
                    onMouseEnter={handleMouseEnter2}
                    onMouseLeave={handleMouseLeave2}
                  >
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      style={{
                        backgroundColor: '#177b9c',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = '#177b9c')
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = 'transparent')
                      }
                    >
                      Corporate Data
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      <div
                        className='sam2'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: '19px',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            as={Link}
                            to=''
                            style={{
                              fontWeight: 'bold',
                              color: 'white',
                              textDecoration: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onMouseEnter={(e) =>
                              (e.target.style.backgroundColor = '#177b9c')
                            }
                            onMouseLeave={(e) =>
                              (e.target.style.backgroundColor = 'transparent')
                            }
                          >
                            ESG
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            style={{
                              fontWeight: 'bold',
                              color: 'white',
                              textDecoration: 'none',
                              backgroundColor: 'transparent',
                              backdropFilter: 'blur(5px)',
                            }}
                            onMouseEnter={(e) =>
                              (e.target.style.backgroundColor = '#177b9c')
                            }
                            onMouseLeave={(e) =>
                              (e.target.style.backgroundColor = 'transparent')
                            }
                          >
                            <Dropdown.Item
                              as={Link}
                              to='/esg/Environmental'
                              style={{
                                fontWeight: 'bold',
                                color: 'white',
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                                backdropFilter: 'blur(5px)',
                              }}
                            >
                              Environmental
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              to='/esg/Social'
                              style={{
                                fontWeight: 'bold',
                                color: 'white',
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                                backdropFilter: 'blur(5px)',
                              }}
                            >
                              Social
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              to='/esg/Governance'
                              style={{
                                fontWeight: 'bold',
                                color: 'white',
                                textDecoration: 'none',
                              }}
                            >
                              Governance
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <Dropdown.Item
                        as={Link}
                        to='/Controversies'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Controversies
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Controversies'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Climate
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to='/Biodiversity'
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#177b9c')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'transparent')
                        }
                      >
                        Biodiversity
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav.Link>
              <Button
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  borderColor: 'transparent',
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                  width: 'max-content',
                  marginBottom: '15px',
                }}
                as={Link}
                to='/research'
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = '#177b9c')
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = 'transparent')
                }
              >
                AI Research
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>{' '}
      <div className='container mt-4'>
        <div id='why-theta'>
          <h2 className='mb-4'>Why Theta?</h2>

          <h4>
            Theta can be thought of as the subconscious. Theta holds memories
            and sensations. Theta governs attitudes, beliefs, and behaviors.
            Theta is creative, inspirational, and very spiritual. Theta allows
            you to act below the level of the conscious mind.
          </h4>
        </div>
      </div>
    </>
  );
};

export default Whytheta;
