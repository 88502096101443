import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../styles.css';

const IsoModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant='transparent'
        style={{
          color: '#177b9c',
          fontWeight: 'bold',
          marginTop: '-6px',
          textDecoration: 'underline', // Add this line to underline the text
        }}
        onClick={handleShow}
      >
        Why ISO 50001
      </Button>

      <Modal
        className='custom-modal'
        show={show}
        onHide={handleClose}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Why ISO 50001 ? </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            ISO 50001 certification offers numerous benefits, particularly for
            organizations focused on energy management. Here are some key
            advantages:
          </p>
          <ol>
            <li>
              <strong>Improved Energy Efficiency</strong>
              <br />
              ISO 50001 provides a structured framework for managing energy,
              leading to more efficient use of energy sources and operational
              processes. This can result in significant cost savings.
            </li>
            <li>
              <strong>Cost Reduction</strong>
              <br />
              By identifying opportunities for energy savings and optimizing
              energy use, companies can reduce their utility costs. This
              efficiency can lead to substantial financial benefits over time.
            </li>
            <li>
              <strong>Enhanced Regulatory Compliance</strong>
              <br />
              Adhering to ISO 50001 helps organizations meet energy-related
              legal and regulatory requirements. It ensures that they stay
              up-to-date with current regulations and standards.
            </li>
            <li>
              <strong>Increased Environmental Responsibility</strong>
              <br />
              The standard promotes the reduction of greenhouse gas emissions
              and environmental impacts. This demonstrates a commitment to
              sustainability and corporate social responsibility.
            </li>
            <li>
              <strong>Competitive Advantage</strong>
              <br />
              Organizations with ISO 50001 certification can differentiate
              themselves in the marketplace. It signals to customers, investors,
              and stakeholders that the company is committed to sustainable
              practices and efficient energy management.
            </li>
            <li>
              <strong>Continuous Improvement</strong>
              <br />
              ISO 50001 encourages a culture of continual improvement. The
              standard's framework fosters ongoing assessment and refinement of
              energy management strategies, ensuring that energy performance
              consistently improves.
            </li>
            <li>
              <strong>Employee Engagement and Awareness</strong>
              <br />
              Implementing ISO 50001 involves training and raising awareness
              among employees about energy efficiency and sustainability. This
              can lead to a more engaged and motivated workforce.
            </li>
            <li>
              <strong>Risk Management</strong>
              <br />
              By systematically managing energy consumption, organizations can
              better control and mitigate risks associated with energy
              availability and price volatility.
            </li>
          </ol>
          <p>
            Overall, ISO 50001 certification is a valuable tool for
            organizations seeking to enhance their energy management practices,
            reduce costs, and demonstrate a commitment to sustainability.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IsoModal;
