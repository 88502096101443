import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './index.css';
import ESG from './components/Solution/ESG';
import Overview from './components/Solution/Overview';
import Data from './components/Solution/Data';
import Research from './components/Solution/Research';
import Main from './components/Main';
import Footer from './components/Footer';
import Whytheta from './components/Solution/Whytheta';
import Sovereign from './components/Solution/Sovereign';
import Solution from './components/Solution/Solution';
import Advisory from './components/Advisory/Advisory';
import ExpertiseAdvisory from './components/Advisory/ExpertiseAdvisory';
import ProjectExpertise from './components/Advisory/ProjectExpertise';
import Boosting from './components/Advisory/Boosting';
import SolutionFinancial from './components/Advisory/SolutionFinancial';
import SolutionNonFinancial from './components/Advisory/SolutionNonFinancial';
import ISO from './components/Advisory/ISO';
import Audit from './components/Advisory/Audit';
import Energy from './components/Advisory/Energy';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './index.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Router>
        <div className='App'>
          <Routes>
            <Route path='/expertise' element={<Overview />} />
            <Route path='/Corporate' element={<Data />} />
            <Route path='/Research' element={<Research />} />
            <Route path='/whytheta' element={<Whytheta />} />
            <Route path='/Sovereign' element={<Sovereign />} />
            <Route path='/Solution' element={<Solution />} />
            <Route path='/Advisory' element={<Advisory />} />
            <Route path='/ESG' element={<ESG />} />
            <Route
              path='/Advisory/ExpertiseAdvisory'
              element={<ExpertiseAdvisory />}
            />
            <Route
              path='/Advisory/ProjectExpertise'
              element={<ProjectExpertise />}
            />
            <Route path='/Advisory/Boosting' element={<Boosting />} />
            <Route path='/Advisory/Boosting/ISO' element={<ISO />} />
            <Route path='/Advisory/Boosting/Audit' element={<Audit />} />
            <Route path='/Advisory/Boosting/Energy' element={<Energy />} />
            <Route
              path='/Advisory/ProjectExpertise/SolutionNonFinancial'
              element={<SolutionNonFinancial />}
            />
            <Route
              path='/Advisory/ProjectExpertise/SolutionFinancial'
              element={<SolutionFinancial />}
            />

            <Route path='/' element={<Main />} />
          </Routes>
        </div>
      </Router>
      <div className='footer'>
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
